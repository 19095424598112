<!--
 * @Author: yzr
 * @Date: 2020-11-24 14:24:20
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-17 15:47:05
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import NoticeManageTableForm from './components';
import SalesArea from './sales_area';
import TargetTable from './target_table';

let timer = null;
formCreate.component('NoticeManageTableForm', NoticeManageTableForm);
formCreate.component('SalesArea', SalesArea);
formCreate.component('TargetTable', TargetTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      cusName: '',
      cusCode: '',
      cusInfo: {},
      targetType: '',
      disableComponents: ['cusCode', 'cusOrgName', 'cusChannelCode'],
      salesArea: '',
      tableData: [],
    };
  },

  async mounted() {
    const formRule = await this.getFormRule('dms-target-add');
    this.rule = formRule;

    // 初始化表单
    this.initEditValue();
    const newRule = formRule.map((item) => {
      let obj = item;
      if (item.field === 'cusName') {
        obj = {
          ...item,
          type: 'NoticeManageTableForm',
          props: {
            cusName: this.cusName,
            edit: this.formConfig.edit,
          },
          validate: [
            { required: true, type: 'string', message: '客户名称不能为空' },
          ],
          on: {
            setCusName: this.setCusName,
            blur: () => {
              this.fApi.validateField('cusName');
            },
          },
          value: '',
        };
      }

      if (item.field === 'salesAreaCode') {
        obj = {
          ...item,
          type: 'SalesArea',
          props: {
            customerCode: this.cusCode,
            salesAreaCode: this.salesArea,
            disabled: true,
          },
          on: {
            setSalesArea: this.setSalesArea,
          },
          value: {},
        };
      }
      if (item.field === 'type') {
        this.getSalesType();
      }

      if (item.field === 'salesTarget') {
        obj = {
          ...item,
          type: 'TargetTable',
          props: {
            targetType: '0',
            edit: this.formConfig.edit,
            tableData: this.tableData,
          },
          on: {
            setTableData: this.setTableData,
          },
          value: '',
        };
      }
      return obj;
    });

    this.rule = [];
    this.rule = newRule;

    // 未选择客户则禁用
    if (this.formConfig.edit) {
      this.disableComponents.push('type');
    }
    this.disableComponents.map((item) => {
      this.getRule(item).props.disabled = true;
      return true;
    });

    this.getRule('type').on.change = this.targetChange;
  },

  methods: {
    // 选多销售区域
    setSalesArea(val) {
      this.salesArea = val;
    },

    // 获取价格类型
    getSalesType() {
      const { edit } = this.formConfig;
      if (!edit) {
        request.post('/mdm/mdmdictdata/list', {
          dictTypeCode: 'salesTarget_type',
        }).then((res) => {
          const type = res.result[0].dictCode;
          this.getRule('type').options = res.result.map((v) => ({
            label: v.dictValue,
            value: v.dictCode,
          }));
          this.setValue({
            type,
          });
          this.targetChange(type);
        });
      }
    },

    // setCusName
    setCusName(val) {
      this.cusName = val.customerName;
      this.cusCode = val.customerCode;
      this.cusInfo = val;
      this.setValue({
        cusName: val.customerName,
        cusCode: val.customerCode,
        cusChannelCode: val.channel,
        cusChannelName: val.channelName,
        cusOrgName: val.orgName,
        cusOrgCode: val.orgCode,
      });
      this.getRule('salesAreaCode').props.customerCode = this.cusCode;
      this.getRule('salesAreaCode').props.disabled = false;
      this.reload();
    },

    // 获取表单数据
    setTableData(val) {
      this.tableData = val;
    },

    // 销售目标类型改变
    targetChange(val) {
      this.getRule('salesTarget').props.targetType = val;
    },

    submit() {
      // if (!this.cusName) {
      //   // this.$message.error('请选择客户名称');
      //   return;
      // }
      console.log(this.tableData);

      if (!this.tableData.length) {
        this.$message.error('销售目标不能为空！');
        return;
      }

      const formData = this.getFormData();

      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        if (formData) this.submitForm();
      }, 500);
    },

    // 提交表单
    submitForm() {
      const { edit } = this.formConfig;
      let requestUrl = '/dms/m/salesTarget/addBatch';
      if (edit) {
        requestUrl = '/dms/m/salesTarget/edit';
      }

      let tabData = [];

      tabData = this.tableData.map((item) => {
        let data = item;
        data = {
          ...data,
          ...this.getFormData(),
          salesAreaCode: this.salesArea,
          goodsName: data.productName,
          goodsCode: data.productCode,
          salesMonthTargetVo: {
            ...data,
          },
        };

        if (edit) {
          data.id = this.formConfig.id;

          if (!this.cusName) {
            data.cusName = this.formConfig.cusName;
            data.cusCode = this.formConfig.cusCode;
          } else {
            data.cusName = this.cusName;
            data.cusCode = this.cusCode;
          }
        } else {
          data.cusName = this.cusName;
          data.cusCode = this.cusCode;
        }
        // console.log(data);
        return data;
      });

      const params = edit ? tabData[0] : tabData;
      // console.log('params', params);
      request.post(requestUrl, params).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功！');
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message.error(res.messge);
        }
      });
    },

    // 编辑赋值
    initEditValue() {
      const { edit } = this.formConfig;
      if (!edit) {
        this.resetFields();
      } else {
        this.getInfo(this.formConfig.id);
        this.cusName = this.formConfig.cusName;
        this.cusCode = this.formConfig.cusCode;
      }
    },

    // 通过id查询销售目标信息
    getInfo(id) {
      request.get(`/dms/m/salesTarget/findById/${id}`).then((res) => {
        const { result } = res;
        this.setValue({
          ...result,
          type: result.type.toString(),
        });
        this.salesArea = result.salesAreaCode;
        this.targetType = result.type.toString();
        this.tableData = [{
          ...result.salesMonthTargetVo,
          targetNum: result.targetNum,
          targetYear: result.targetYear,
          productCode: result.goodsCode,
          productName: result.goodsName,
          productLevelCode: result.productLevelCode,
          productLevelName: result.productLevelName,
        }];
        this.getRule('salesTarget').props.tableData = this.tableData;
        this.getRule('salesTarget').props.targetType = result.type.toString();
        this.getRule('salesAreaCode').props.salesAreaCode = result.salesAreaCode;
        // this.getRule('salesAreaCode').props.disabled = false;
      });
    },
  },
};
</script>
