<!--
 * @Author: yzr
 * @Date: 2020-11-29 15:47:22
 * @LastEditors: yzr
 * @LastEditTime: 2021-08-04 10:36:24
-->
<template>
  <div>
    <div v-for="(table, index) in tableInfo"
         :key="index">
      <div v-if="targetType === table.type">
        <vxe-toolbar v-if="!edit">
          <template v-slot:buttons>
            <vxe-button @click="insertEvent()">新增</vxe-button>
            <vxe-button @click="delRow">删除</vxe-button>
            <i v-if="isEmpty"
               class="el-icon-warning warningIcon">销售目标不能为空！</i>
          </template>
        </vxe-toolbar>
        <vxe-table border
                   ref="xTable"
                   show-header-overflow
                   show-overflow
                   highlight-hover-row
                   :align="allAlign"
                   :data="table.data"
                   show-footer
                   :footer-method="footerMethod"
                   :edit-config="{ trigger: 'click', mode: 'row' }">
          <vxe-table-column type="seq"
                            title="序号"
                            width="60"
                            fixed="left"></vxe-table-column>
          <vxe-table-column type="checkbox"
                            width="40"
                            fixed="left"></vxe-table-column>
          <vxe-table-column v-for="(col, index) in table.columns"
                            :key="index"
                            :field="col.field"
                            :title="col.title"
                            :edit-render="col.editRender
            && {...col.editRender,
            props : {...col.editRender.props ,
            disabled: tableDisable(edit , col.field)}}"
                            :min-width="col.width ? col.width : 80"
                            :fixed='col.fixed'>
            <template v-slot:default="{row , rowIndex}"
                      v-if="col.field === 'targetNum'">
              {{countAmount(row , rowIndex)}}
            </template>
          </vxe-table-column>
        </vxe-table>
      </div>
    </div>
    <!-- 弹窗 -->
    <SelectConfig ref="selectConfig"
                  @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
// import request from '@/found/utils/request';
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'TargetTable',
  props: {
    targetType: String,
    edit: String,
    tableData: Array,
  },
  components: {
    SelectConfig,
  },
  watch: {
    tableData: {
      deep: true,
      immediate: true,
      handler(v) {
        if (this.edit) {
          this.tableInfo.map((item) => {
            const data = item;
            if (data.type === this.targetType) {
              data.data = v;
            }
            return data;
          });
        }
      },
    },
  },

  data() {
    return {
      allAlign: null,
      rowIndex: '',
      requestUrl: '/mdm/mdmRegionController/select',
      isEmpty: false,
      tableInfo: [
        {
          type: '0',
          columns: [
            {
              fixed: 'left',
              field: 'targetYear',
              title: '年度',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              fixed: 'left',
              field: 'targetNum',
              title: '年总量',
            },
            {
              field: 'janTargetNum',
              title: '一月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'febTargetNum',
              title: '二月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'marTargetNum',
              title: '三月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'aprTargetNum',
              title: '四月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'mayTargetNum',
              title: '五月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'junTargetNum',
              title: '六月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'julTargetNum',
              title: '七月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'augTargetNum',
              title: '八月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'sepTargetNum',
              title: '九月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'octTargetNum',
              title: '十月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'novTargetNum',
              title: '十一月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'decTargetNum',
              title: '十二月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
          ],
          data: [
            {
              productName: '',
              productCode: '',
              targetYear: '2020',
              targetNum: 0,
              febTargetNum: 0,
              janTargetNum: 0,
              marTargetNum: 0,
              aprTargetNum: 0,
              mayTargetNum: 0,
              junTargetNum: 0,
              julTargetNum: 0,
              augTargetNum: 0,
              sepTargetNum: 0,
              octTargetNum: 0,
              novTargetNum: 0,
              decTargetNum: 0,
            },
          ],
        },
        {
          type: '1',
          columns: [
            {
              width: '100',
              fixed: 'left',
              field: 'productName',
              title: '产品名称',
              editRender: {
                name: '$input',
                props: { readonly: true },
                events: { focus: this.openModal },
              },
            },
            {
              width: '100',
              fixed: 'left',
              field: 'productCode',
              title: '产品编码',
            },
            {
              fixed: 'left',
              field: 'targetYear',
              title: '年度',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              fixed: 'left',
              field: 'targetNum',
              title: '年总量',
            },
            {
              field: 'janTargetNum',
              title: '一月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'febTargetNum',
              title: '二月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'marTargetNum',
              title: '三月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'aprTargetNum',
              title: '四月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'mayTargetNum',
              title: '五月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'junTargetNum',
              title: '六月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'julTargetNum',
              title: '七月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'augTargetNum',
              title: '八月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'sepTargetNum',
              title: '九月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'octTargetNum',
              title: '十月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'novTargetNum',
              title: '十一月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'decTargetNum',
              title: '十二月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
          ],
          data: [
            {
              productName: '',
              productCode: '',
              targetYear: '2020',
              targetNum: 0,
              febTargetNum: 0,
              janTargetNum: 0,
              marTargetNum: 0,
              aprTargetNum: 0,
              mayTargetNum: 0,
              junTargetNum: 0,
              julTargetNum: 0,
              augTargetNum: 0,
              sepTargetNum: 0,
              octTargetNum: 0,
              novTargetNum: 0,
              decTargetNum: 0,
            },
          ],
        },
        {
          type: '2',
          columns: [
            {
              width: '100',
              fixed: 'left',
              field: 'productLevelName',
              title: '产品层级',
              editRender: {
                name: '$input',
                props: { readonly: true },
                events: { focus: this.openModal },
              },
            },
            {
              width: '100',
              fixed: 'left',
              field: 'productLevelCode',
              title: '产品层级编码',
            },
            {
              fixed: 'left',
              field: 'targetYear',
              title: '年度',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              fixed: 'left',
              field: 'targetNum',
              title: '年总量',
            },
            {
              field: 'janTargetNum',
              title: '一月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'febTargetNum',
              title: '二月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'marTargetNum',
              title: '三月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'aprTargetNum',
              title: '四月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'mayTargetNum',
              title: '五月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'junTargetNum',
              title: '六月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'julTargetNum',
              title: '七月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'augTargetNum',
              title: '八月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'sepTargetNum',
              title: '九月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'octTargetNum',
              title: '十月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'novTargetNum',
              title: '十一月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
            {
              field: 'decTargetNum',
              title: '十二月',
              editRender: { name: '$input', props: { type: 'number', min: 0 } },
            },
          ],
          data: [
            {
              productLevelName: '',
              productLevelCode: '',
              targetYear: '2020',
              targetNum: 0,
              febTargetNum: 0,
              janTargetNum: 0,
              marTargetNum: 0,
              aprTargetNum: 0,
              mayTargetNum: 0,
              junTargetNum: 0,
              julTargetNum: 0,
              augTargetNum: 0,
              sepTargetNum: 0,
              octTargetNum: 0,
              novTargetNum: 0,
              decTargetNum: 0,
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.intData();
  },
  methods: {
    tableDisable(edit, field) {
      if (edit === 'view') {
        return true;
      } if (edit === 'edit') {
        if (field === 'productName' || field === 'productLevelName') {
          return true;
        }
      }
      return false;
    },
    // 初始化
    intData() {
      // 编辑时无数据类型则默认新增一条空数据
      this.tableInfo.map((item) => {
        const data = item;
        if (!data.data.length) {
          switch (data.type) {
            case '0':
              data.data.push({
                productName: '',
                productCode: '',
                targetYear: '2020',
                targetNum: 0,
                febTargetNum: 0,
                janTargetNum: 0,
                marTargetNum: 0,
                aprTargetNum: 0,
                mayTargetNum: 0,
                junTargetNum: 0,
                julTargetNum: 0,
                augTargetNum: 0,
                sepTargetNum: 0,
                octTargetNum: 0,
                novTargetNum: 0,
                decTargetNum: 0,
              });
              break;
            case '1':
              data.data.push({
                productName: '',
                productCode: '',
                targetYear: '2020',
                targetNum: 0,
                febTargetNum: 0,
                janTargetNum: 0,
                marTargetNum: 0,
                aprTargetNum: 0,
                mayTargetNum: 0,
                junTargetNum: 0,
                julTargetNum: 0,
                augTargetNum: 0,
                sepTargetNum: 0,
                octTargetNum: 0,
                novTargetNum: 0,
                decTargetNum: 0,
              });
              break;
            case '2':
              data.data.push({
                productLevelName: '',
                productLevelCode: '',
                targetYear: '2020',
                targetNum: 0,
                febTargetNum: 0,
                janTargetNum: 0,
                marTargetNum: 0,
                aprTargetNum: 0,
                mayTargetNum: 0,
                junTargetNum: 0,
                julTargetNum: 0,
                augTargetNum: 0,
                sepTargetNum: 0,
                octTargetNum: 0,
                novTargetNum: 0,
                decTargetNum: 0,
              });
              break;
            default:
              break;
          }
        }
        return data;
      });
    },

    async insertEvent(row) {
      const record = {
        targetYear: '2020',
        targetNum: 0,
        febTargetNum: 0,
        janTargetNum: 0,
        marTargetNum: 0,
        aprTargetNum: 0,
        mayTargetNum: 0,
        junTargetNum: 0,
        julTargetNum: 0,
        augTargetNum: 0,
        sepTargetNum: 0,
        octTargetNum: 0,
        novTargetNum: 0,
        decTargetNum: 0,
      };
      if (this.targetType === '1') {
        record.productName = '';
        record.productCode = '';
      }

      if (this.targetType === '2') {
        record.productLevelName = '';
        record.productLevelCode = '';
      }

      const { row: newRow } = await this.$refs.xTable[0].insertAt(record, row);
      await this.$refs.xTable[0].setActiveCell(newRow, 'sex');
    },

    onGetSelect(val) {
      const { fullData } = this.$refs.xTable[0].getTableData();
      if (this.targetType === '1') {
        fullData[this.rowIndex].productName = val[0].productName;
        fullData[this.rowIndex].productCode = val[0].productCode;
        fullData[this.rowIndex].productSpec = val[0].spec;
        fullData[this.rowIndex].productLevelCode = val[0].productLevelCode;
        fullData[this.rowIndex].productLevelName = val[0].productLevelName;
      }

      if (this.targetType === '2') {
        fullData[this.rowIndex].productLevelName = val[0].productLevelName;
        fullData[this.rowIndex].productLevelCode = val[0].productLevelCode;
      }

      this.tableInfo.map((item) => {
        const data = item;
        if (data.type === this.targetType) {
          data.data = fullData;
        }
        return data;
      });
    },

    // 打开弹窗
    openModal(val) {
      this.rowIndex = val.$rowIndex;

      const params = {
        functionCode: 'dms-target-products',
        data: [],
        selectionList: [],
        idKey: 'productCode',
      };

      if (this.targetType === '2') {
        params.functionCode = 'dms-target-productLevel';
        params.idKey = 'productLevelCode';
      }
      if (!this.edit) {
        this.$refs.selectConfig.openSelectModal(params);
      }
    },

    delRow() {
      this.$refs.xTable[0].removeCheckboxRow();
    },
    countAmount(row) {
      const plusKey = [
        'febTargetNum',
        'janTargetNum',
        'marTargetNum',
        'aprTargetNum',
        'mayTargetNum',
        'junTargetNum',
        'julTargetNum',
        'augTargetNum',
        'sepTargetNum',
        'octTargetNum',
        'novTargetNum',
        'decTargetNum',
      ];
      let targetNum = 0;
      plusKey.forEach((val) => {
        targetNum = this.accAdd(targetNum, parseFloat(row[val]));
      });
      // eslint-disable-next-line no-param-reassign
      row.targetNum = targetNum;
      const { tableData } = this.$refs.xTable[0].getTableData();
      this.$emit('setTableData', tableData);
      return targetNum;
    },
    countAllAmount(data) {
      let count = 0;
      data.forEach((v) => (count = this.accAdd(count, this.countAmount(v))));
      return count;
    },

    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          let isCount = true;
          switch (column.property) {
            case 'productName':
              isCount = false;
              break;
            case 'productCode':
              isCount = false;
              break;
            case 'targetYear':
              isCount = false;
              break;
            case 'productLevelName':
              isCount = false;
              break;
            case 'productLevelCode':
              isCount = false;
              break;

            default:
              isCount = true;
              break;
          }
          if (!isCount || columnIndex === 1) {
            return '-';
          }
          if (columnIndex === 0) {
            return '合计';
          }
          if (column.property === 'targetNum') {
            return this.countAllAmount(data);
          }
          let count = 0;
          data.forEach((v) => {
            if (v[column.property] > 0) {
              count = this.accAdd(count, parseFloat(v[column.property]));
            }
          });
          return count;
          // return '-';
        }),
      ];
    },
    // 两个浮点数求和
    accAdd(num1, num2) {
      let r1;
      let r2;
      let m = 0;
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      // eslint-disable-next-line no-restricted-properties
      m = Math.pow(10, Math.max(r1, r2));
      // return (num1*m+num2*m)/m;
      return Math.round(num1 * m + num2 * m) / m;
    },
  },
};
</script>

<style scoped>
.warningIcon {
  font-size: 14px;
  margin-left: 10px;
  color: red;
}
</style>
